import React from 'react';
import {Container, Stack, Box, Grid, LinearProgress} from "@mui/material";
import {zoomInDown} from 'react-animations';
import styled, {keyframes} from 'styled-components';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, {timelineItemClasses} from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import {
    about1,
    about2,
    experience,
    languagesSkills,
    otherSkills,
    secondaryColor,
    skills
} from "../util/constants";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HeaderMobile from "./HeaderMobile";

const fadeInDownAnimation = keyframes`${zoomInDown}`;

const Animation = styled.div`
  animation: 2s ${fadeInDownAnimation};
`;


export default function About() {

    return (
        <Container maxWidth="xl">
            <Stack direction="column" spacing={6} className={'padding'}>
                <Animation>
                    <Stack direction="column" spacing={2}>
                        <HeaderMobile/>
                        <span className={'title'}>About me</span>
                        <span className={'description'}>{about1}</span>
                        <span className={'description'} style={{marginTop: 15}}>{about2}</span>
                    </Stack>


                    <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                        <span className={'title'}>Work experience</span>
                        <Box>
                            <Timeline
                                sx={{
                                    [`& .${timelineItemClasses.root}:before`]: {
                                        flex: 0,
                                        padding: 0,
                                    },
                                }}
                            >
                                {experience.map((e, index) => {
                                    return (
                                        <TimelineItem key={index}>
                                            <TimelineSeparator>
                                                <TimelineDot sx={{backgroundColor: '#000'}}/>
                                                {experience.length !== (index + 1) && (
                                                    <TimelineConnector sx={{backgroundColor: '#000'}}/>
                                                )}
                                            </TimelineSeparator>
                                            <TimelineContent sx={{paddingBottom: 5}}>
                                                <Stack direction="column" alignContent={'flex-start'}
                                                       justifyContent="flex-start" alignItems="flex-start">
                                                    <span style={{
                                                        color: '#000',
                                                        fontWeight: 'bold',
                                                        textTransform: 'capitalize'
                                                    }}>
                                                        {e.job} at <b style={{color: secondaryColor}}>{e.name}</b>
                                                    </span>
                                                    <Stack direction="row" alignItems="center">
                                                        <AccessTimeIcon
                                                            style={{marginRight: 5, color: secondaryColor}}/>
                                                        <span className={'text14'}>{e.date}</span>
                                                    </Stack>

                                                    <Stack direction="column" alignItems="flex-start">
                                                        {e.description.map((d, index) => {
                                                            return (<span key={index} className={'text16'}
                                                                          style={{marginTop: 10}}>• {d}</span>)
                                                        })}
                                                    </Stack>

                                                </Stack>
                                            </TimelineContent>
                                        </TimelineItem>
                                    )
                                })}
                            </Timeline>
                        </Box>
                    </Stack>


                    <Stack direction="column" spacing={4}>
                        <Box>
                            <span className={'title'}>Skills</span>
                            <Grid container spacing={3} style={{marginTop: 1}}>
                                {skills.map(s => {
                                    return (
                                        <Grid key={s.name} item xs={12} sm={6} md={4}>
                                            <Box>
                                                <Stack direction="row" justifyContent="space-between">
                                                    <span>{s.name}</span>
                                                    <span>{s.value}%</span>
                                                </Stack>
                                                <LinearProgress sx={{borderRadius: 4, height: 10,}} variant="buffer"
                                                                valueBuffer={100} value={s.value}/>
                                            </Box>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Box>
                        <Box>
                            <span className={'title'}>Others Skills</span>
                            <Grid container spacing={3} style={{marginTop: 1}}>
                                {otherSkills.map(s => {
                                    return (
                                        <Grid key={s.name} item xs={12} sm={6} md={4}>
                                            <Box>
                                                <Stack direction="row" justifyContent="space-between">
                                                    <span>{s.name}</span>
                                                    <span>{s.value}%</span>
                                                </Stack>
                                                <LinearProgress sx={{borderRadius: 4, height: 10,}} variant="buffer"
                                                                valueBuffer={100} value={s.value}/>
                                            </Box>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Box>
                        <Box>
                            <span className={'title'}>Languages</span>
                            <Grid container spacing={3} style={{marginTop: 1}}>
                                {languagesSkills.map(s => {
                                    return (
                                        <Grid key={s.name} item xs={12} sm={6} md={6}>
                                            <Box>
                                                <Stack direction="row" justifyContent="space-between">
                                                    <span>{s.name}</span>
                                                    <span>{s.value}%</span>
                                                </Stack>
                                                <LinearProgress sx={{borderRadius: 4, height: 10,}} variant="buffer"
                                                                valueBuffer={100} value={s.value}/>
                                            </Box>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Box>
                    </Stack>
                </Animation>

            </Stack>
        </Container>
    );
}


