import React from 'react';
import {Stack, Box, IconButton, Button} from "@mui/material";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import {useLocation, useNavigate} from "react-router-dom";
import profile from "../assest/profile2.JPG";
import styled, {keyframes} from "styled-components";
import {fadeInRight, zoomIn} from "react-animations";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";

import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import {PDFDownloadLink} from "@react-pdf/renderer";
import DocumentPdf from "./DocumentPdf";

const fadeInRightAnimation = keyframes`${fadeInRight}`;

const ZoomInDiv = styled.div`
  animation: 3s ${fadeInRightAnimation};
  max-width: 250px;
  height: 250px;
`;

const FadeInRightAnimationDiv = styled.div`
  animation: 3s ${fadeInRightAnimation};
  flex-direction: column;
  display: flex;
`;

export default function Menu() {

    const navigate = useNavigate();
    const location = useLocation();
    const [active, setActive] = React.useState(location.pathname)

    React.useEffect(() => {
        setActive(location.pathname)
    }, [location])


    return (
        <Box className='menu-container'>
            <Box className={'menu'}>
                <Stack direction="column" spacing={1}>
                    {location.pathname !== '/' && (
                        <>
                            <ZoomInDiv>
                                <img
                                    style={{objectFit: 'cover', borderRadius: 5}}
                                    height={'100%'}
                                    width={'100%'}
                                    src={profile}
                                    alt="Jansi Hernandez"
                                />
                            </ZoomInDiv>
                            <Stack direction="row" spacing={2} sx={{paddingTop: 5}}>
                                <FadeInRightAnimationDiv>

                                    {/*<PDFDownloadLink style={{textDecoration: 'none'}} document={<DocumentPdf/>}*/}
                                    {/*                 fileName="Jansi Hernandez.pdf">*/}
                                    {/*    {({blob, url, loading, error}) =>*/}
                                    {/*        <Button color={'info'} size={'medium'}*/}
                                    {/*                sx={{*/}
                                    {/*                    marginBottom: 2,*/}
                                    {/*                    textTransform: 'capitalize',*/}
                                    {/*                    fontSize: 18,*/}
                                    {/*                    backgroundColor: '#fff',*/}
                                    {/*                    borderColor: '#fff',*/}
                                    {/*                    borderWidth: 1,*/}
                                    {/*                    borderStyle: 'solid',*/}
                                    {/*                    color: "#000",*/}
                                    {/*                    "&:hover": {*/}
                                    {/*                        borderWidth: 1,*/}
                                    {/*                        borderStyle: 'solid',*/}
                                    {/*                        borderColor: '#fff',*/}
                                    {/*                        backgroundColor: '#000',*/}
                                    {/*                        color: "#fff",*/}
                                    {/*                    },*/}
                                    {/*                }}*/}
                                    {/*                variant="contained"*/}
                                    {/*                endIcon={<DownloadOutlinedIcon/>}>*/}
                                    {/*            Download CV*/}
                                    {/*        </Button>*/}
                                    {/*    }*/}
                                    {/*</PDFDownloadLink>*/}

                                    <Button onClick={() => navigate('/contact')} color={'info'} size={'medium'}
                                            sx={{
                                                textTransform: 'capitalize',
                                                fontSize: 18,
                                                borderWidth: 1,
                                                borderStyle: 'solid',
                                                borderColor: '#fff',
                                                backgroundColor: '#000',
                                                color: "#fff",
                                                "&:hover": {
                                                    backgroundColor: '#fff',
                                                    borderColor: '#fff',
                                                    borderWidth: 1,
                                                    borderStyle: 'solid',
                                                    color: "#000",
                                                },
                                            }}
                                            variant="outlined"
                                            endIcon={<SendOutlinedIcon/>}>
                                        Contact me
                                    </Button>
                                </FadeInRightAnimationDiv>
                            </Stack>
                        </>
                    )}
                    <Box>
                        <h1 style={{fontSize: 16}}>Name</h1>
                        <h2 style={{fontSize: 16}}>Jansi Hernández</h2>
                    </Box>
                    {/*<Box>*/}
                    {/*    <h1 style={{fontSize: 16}}>Email</h1>*/}
                    {/*    <h2 style={{fontSize: 16}}>jansihdez@gmail.com</h2>*/}
                    {/*</Box>*/}
                    {/*<Box>*/}
                    {/*    <h1 style={{fontSize: 16}}>Phone</h1>*/}
                    {/*    <h2 style={{fontSize: 16}}>(829) 299-5294</h2>*/}
                    {/*</Box>*/}
                </Stack>
                <Stack style={{marginTop: 10}} direction="row" spacing={2}>
                    <IconButton onClick={() => {window.open('https://github.com/jansi-hernandez', '_blank', 'noreferrer')}} style={{color: '#fff'}}>
                        <GitHubIcon style={{fontSize: 30}}/>
                    </IconButton>

                    <IconButton onClick={() => {window.open('https://www.linkedin.com/in/jansihernandez', '_blank', 'noreferrer')}} style={{color: '#fff'}}>
                        <LinkedInIcon style={{fontSize: 30}}/>
                    </IconButton>
                </Stack>

                <Box style={{marginTop: 50}}>
                    <h2 style={{fontSize: 16}}>v3.0.0</h2>
                </Box>
            </Box>
        </Box>
    );
}


