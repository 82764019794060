import React from 'react';
import {isMobile} from 'react-device-detect';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Home from '@mui/icons-material/Home';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Person from '@mui/icons-material/Person';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import Work from '@mui/icons-material/Work';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import {IconButton} from "@mui/material";
import {useNavigate, useLocation} from "react-router-dom";

import {fadeInUp, zoomIn} from 'react-animations';
import styled, {keyframes} from 'styled-components';

const slideInDownAnimation = keyframes`${zoomIn}`;
const fadeInDownAnimation = keyframes`${fadeInUp}`;

const SlideInDownDiv = styled.div`
  animation: 3s ${slideInDownAnimation};
  background-color: #000;
  position: fixed;
  padding: 15px 0 15px 0;
  right: 0;
  transform: translate(-50%, -50%);
  top: 50%;
  flex-direction: column;
  display: flex;
  border-radius: 10px;
  box-shadow: 0 10px 15px 2px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const FadeInDown3 = styled.div`
  animation: 3s ${fadeInDownAnimation};
  background-color: #000;
  position: fixed;
  bottom: 10px;
  width:90%;
  justify-content: space-around;
  flex-direction: row;
  display: flex;
  border-radius: 10px;
  box-shadow: 0 10px 15px 2px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  margin: 5% auto;
  left: 0;
  right: 0;
`;



const routers = [
    {name: '/', icon: <HomeOutlinedIcon className={'icon'}/>, iconSelected: <Home className={'icon'}/>},
    {name: '/about', icon: <PersonOutlineOutlinedIcon className={'icon'}/>, iconSelected: <Person className={'icon'}/>},
    // {name: '/projects', icon: <WorkOutlineOutlinedIcon className={'icon'}/>, iconSelected: <Work className={'icon'}/>},
    {
        name: '/contact',
        icon: <ContactMailOutlinedIcon className={'icon'}/>,
        iconSelected: <ContactMailIcon className={'icon'}/>
    },
]

export default function NavigationButton() {
    const navigate = useNavigate();
    const location = useLocation();
    const [active, setActive] = React.useState(location.pathname)

    React.useEffect(() => {
        setActive(location.pathname)
    }, [location])

    return (
        <>
            {isMobile ? (
                <FadeInDown3>
                    {routers.map(router => {
                        return <IconButton key={router.name} onClick={() => navigate(router.name)}
                                           style={{color: active === router.name ? '#025e91' : 'gray', fontSize: 24}}>
                            {active === router.name ? router.iconSelected : router.icon}
                        </IconButton>
                    })}
                </FadeInDown3>
            ) : (
                <SlideInDownDiv>
                    {routers.map(router => {
                        return <IconButton key={router.name} onClick={() => navigate(router.name)}
                                           style={{color: active === router.name ? '#025e91' : 'gray', fontSize: 24}}>
                            {active === router.name ? router.iconSelected : router.icon}
                        </IconButton>
                    })}
                </SlideInDownDiv>
            )}
        </>
    );
}


