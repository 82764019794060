import p1 from '../assest/projects/p1.png'
import p3 from '../assest/projects/p3.png'
import p6 from '../assest/projects/p6.png'
import p2 from '../assest/projects/p2.png'
import p4 from '../assest/projects/p4.png'
import p7 from '../assest/projects/p7.png'
import p8 from '../assest/projects/p8.png'

export const mainColor = '#000'
export const secondaryColor = '#646f87'


export const about1 = 'I\'m a software engineer from Santiago, Dominican Republic. I have more than 5 years of experience in web and mobile development.\n' +
    '                                    I\'m writing code in different programming languages, testing software to ensure quality, collaborating with cross-functional teams,\n' +
    '                                    and troubleshooting technical issues. Also, I\'m involved in the full software development life cycle, including requirement gathering, design,\n' +
    '                                    implementation, and maintenance.'

export const about2 = 'I keep myself in continuous learning, as technology and industry standards are constantly evolving.With the objective of being able to apply this knowledge in the work environment.\n'

export const experience = [
    {
        date: "Sep 2021 - Present",
        name: 'Softimatic',
        job: 'Full Stack Software Developer',
        description: [
            'Monitor application performance, watch for traffic crashes related to site usability issues and rectify any related to site usability issues and rectify any problems',
            'Optimize applications for maximum speed and scalability',
            'Design new functions and/or optimize/maintain old ones',
            'Write reusable code and libraries (with associated documentation) according to a standard that makes future code maintenance easier and faster',
            'Improving the user experience with the help of the analytics',
        ]
    },
    {
        date: "Mar 2019 - Nov 2021",
        name: 'Electrical Equipment Supplier',
        job: 'Full Stack Software Developer',
        description: [
            'Responsible for the design, development, testing and deployment of applications for the Dominican for the Dominican Republic\'s electricity industry',
            'Monitor application performance, watch for traffic crashes related to site usability issues and rectify any related to site usability issues and rectify any problems',
            'Working with Web App in React for automation of electrical substations',
        ]
    },
    {
        date: "Aug 2013 - Mar 2019",
        name: 'Edenorte',
        job: 'Call Center Supervisor',
        description: ['Optimize processes by making macros in excel using C#.', 'Teamwork to improve the quality of service in conjunction with other supervisors']
    },
]

export const skills = [
    {name: 'React js', value: 99},
    {name: 'React Native', value: 99},
    {name: 'JavaScript', value: 99},
    {name: 'HTML5', value: 99},
    {name: 'CSS', value: 99},
    {name: 'Node js', value: 95},
    {name: 'MySQL', value: 90},
    {name: 'Graphic Design', value: 85},
    {name: 'Wordpress', value: 85},
    {name: 'PHP', value: 85},
    {name: 'C#', value: 75},
    {name: 'C# .NET CORE Web API', value: 75},
]

export const otherSkills = [
    {name: 'Problem-solving', value: 100},
    {name: 'Listening', value: 100},
    {name: 'Collaboration', value: 100},
    {name: 'Communication', value: 95},
    {name: 'Leadership', value: 95},
]


export const languagesSkills = [
    {name: 'Spanish', value: 100},
    {name: 'English', value: 80},
]


export const projects = [
    {
        name: 'Outcrew',
        skill: ['React', 'React Native', 'Javascript ', 'CSS', 'Firebase'],
        description: "This application made with the aim of helping people to find nearby events with the help of a mobile application, users can create events and join the same, developed with a great team.",
        img: p1
    },
    {
        name: 'Shipment Software',
        skill: ['React', 'React Native', 'Javascript ', 'CSS', 'Firebase'],
        description: "With an admin panel following a logistical input and output of goods by state, the panel consists of several configurations to take full control of all goods received by the customer application and at the same time to control and track the drivers through the mobile application.",
        img: p3
    },
    {
        name: 'Taxi Software',
        skill: ['React', 'React Native', 'Javascript ', 'CSS', 'Firebase'],
        description: "Admin panel  contemplated by many configuration options. This panel consists of multiple functionalities, which help to improve the performance of any taxi base. all its functionalities operate in real time, allowing the work to be more fluid for all users who use the system. Allows you to control the driver and client applications to 100%.",
        img: p6
    },
    {
        name: 'Billing Software',
        skill: ['React', 'React Native', 'Javascript ', 'CSS', 'Nodejs'],
        description: "Designed application that is designed to automate the process of generating invoices and handling payments for goods or services rendered. This type of software helps to streamline the billing process, reducing manual effort and improving accuracy and efficiency. Billing software typically includes features such as invoicing, payment processing, account management, and reporting",
        img: p8
    },
    {
        name: 'Channel App',
        skill: ['React', 'React Native', 'Android TV', 'Javascript ', 'CSS', 'Nodejs'],
        description: "Custom application to download other applications from other sources and install it with its main functionality of watching channels online. ",
        img: p7
    },
    {
        name: 'Club De La Salud',
        skill: ['React', 'Javascript ', 'CSS', 'Paypal', 'Firebase'],
        description: "An idea in development which will allow people abroad to have health care in the country without having to be affiliated with an insurance company, this software allows the user to request appointments and view medical status online all in real time.",
        img: p2
    },
    {
        name: 'Salud A Tu Alcance',
        skill: ['React', 'Javascript ', 'CSS', 'Paypal', 'Firebase'],
        description: "This software is oriented to provide hospital engineering services, it has an administrative panel to process purchase orders and optimize the invoice process.",
        img: p4
    },
]
