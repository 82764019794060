import React from 'react';
import {Container, Grid, Paper, Stack, Chip} from "@mui/material";
import {projects} from "../util/constants";
import styled, {keyframes} from "styled-components";
import {zoomInDown} from "react-animations";
import HeaderMobile from "./HeaderMobile";

const fadeInDownAnimation = keyframes`${zoomInDown}`;

const Animation = styled.div`
  animation: 2s ${fadeInDownAnimation};
`;

export default function Projects() {

    return (
        <Container maxWidth="xl">
            <Animation>
                <Stack direction="column" className={'padding'}>
                    <HeaderMobile/>
                    <span className={'title'}>Projects</span>

                    {projects.map((p, index) => {
                        return (
                            <Grid key={index} container style={{marginBottom: 150}}>
                                <Grid item xs={12} md={6}>
                                    <Stack direction="column" spacing={2}>
                                        <span className={'text24'}>{p.name}</span>
                                           <div style={{flexWrap:'wrap', display:'flex',  gap: 10}}>
                                               {p.skill.map((sk, index) => {
                                                   return <Chip style={{color:'#fff', fontWeight:'bold', backgroundColor:'#000', borderRadius:4}} key={p.name + sk} label={sk}/>
                                               })}
                                           </div>
                                        <span className={'description'} style={{paddingRight:10, paddingBottom:15}}>{p.description}</span>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Paper elevation={10}>
                                        <img
                                            style={{objectFit: 'cover', borderRadius: 5}}
                                            height={'90%'}
                                            width={'90%'}
                                            src={p.img}
                                            alt={p.name}
                                        />
                                    </Paper>

                                </Grid>
                            </Grid>
                        )
                    })}

                </Stack>
            </Animation>
        </Container>
    );
}


