import React from 'react';
import {Button, Container, Grid, Stack, TextField} from "@mui/material";
import emailjs from '@emailjs/browser';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import styled, {keyframes} from "styled-components";
import {zoomInDown} from "react-animations";
import {useForm, Controller} from "react-hook-form";
import Swal from 'sweetalert2'
import {mainColor} from "../util/constants";
import HeaderMobile from "./HeaderMobile";

const fadeInDownAnimation = keyframes`${zoomInDown}`;

const FadeInDown4 = styled.div`
  animation: 2s ${fadeInDownAnimation};
`;

export default function Contact() {

    const {reset, control, handleSubmit, formState: {errors}} = useForm({
        defaultValues: {
            name: '',
            email: '',
            message: ''
        }
    });

    const onSubmit = (data) => {
        const templateParams = {
            name: data.name,
            email: data.email,
            message: data.message
        };


        emailjs.send('service_wgekh8y', 'template_de42kwl', templateParams, 'YCE7ER9F-fW70CXrT')
            .then((response) => {
                Swal.fire({
                    title: 'Message sent successfully',
                    confirmButtonText: 'Cool',
                    confirmButtonColor: mainColor,
                })
                reset()
            }, (err) => {
                Swal.fire({
                    title: 'Oops...',
                    text: 'Something went wrong!, try again later',
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: mainColor,
                })
            });
    }


    return (
        <Container maxWidth="xl">
            <Stack direction="column" spacing={6} className={'padding'}>
                <FadeInDown4>
                    <HeaderMobile/>
                      <span className={'title'} style={{textAlign:'center'}}>Contact <br/></span>
                    <span className={'text1'}>Would you like me to be part of your team? Let's talk</span>
                </FadeInDown4>

                <FadeInDown4>
                    <Grid container direction={'row'} spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        label={'Name'}
                                        placeholder={'Name'}
                                        fullWidth

                                    />
                                )}
                            />
                            {errors.name && <b style={{color: 'red'}}>This is required.</b>}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Controller
                                control={control}
                                rules={{
                                    required: true,
                                    pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                }}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        label={'Email'}
                                        placeholder={'Email'}
                                        fullWidth
                                    />
                                )}
                                name="email"
                            />
                            {errors.email && <b style={{color: 'red'}}>Invalid email address.</b>}
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        label={'Message'}
                                        placeholder={'Message'}
                                        fullWidth

                                    />
                                )}
                                name="message"
                            />
                            {errors.message && <b style={{color: 'red'}}>This is required.</b>}
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={handleSubmit(onSubmit)} color={'info'} size={'large'}
                                    sx={{
                                        marginRight: 4,
                                        textTransform: 'capitalize',
                                        fontSize: 18,
                                        backgroundColor: '#000',
                                        borderColor: '#000',
                                        color: "#fff",
                                        "&:hover": {
                                            borderColor: 'rgba(0,0,0,0.7)',
                                            backgroundColor: 'rgba(0,0,0,0.7)',
                                        },
                                    }}
                                    fullWidth
                                    variant="contained"
                                    endIcon={<SendOutlinedIcon/>}>
                                Send message
                            </Button>
                        </Grid>
                    </Grid>
                </FadeInDown4>

            </Stack>
        </Container>
    );
}


