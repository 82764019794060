import React from 'react';
import p from '../assest/profile2.JPG'
import {Document, Page, Text, View, StyleSheet, Image, Font, Link} from '@react-pdf/renderer';
import {
    about1,
    about2,
    experience,
    languagesSkills,
    otherSkills,
    skills
} from "../util/constants";

Font.register({
    family: 'Roboto',
    fonts: [
        {src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf'},
        {src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 900}
    ]
});



export default function DocumentPDF() {
   return <Document>
        <Page size="A4" style={styles.page}>
            <View>
                <View style={styles.header}>
                    <View style={{borderRadius: 5}}>
                        <Image style={{width: 80, height: 80, objectFit: 'contain', borderRadius: 5}} src={p}/>
                    </View>
                    <View style={{flexDirection: 'column'}}>
                        <Text style={styles.title}>Jansi Hernandez</Text>
                        <Text style={{fontSize: 13}}>Full Stack Software Developer</Text>
                        <Link style={{fontSize: 13, color:'#01579b'}} src={'https://jansihernandez.com'}>www.jansihernandez.com</Link>
                    </View>
                </View>

                <View style={styles.body}>
                    <View style={styles.contentLeft}>
                        <View style={styles.view}>
                            <Text style={styles.subtitle}>About me</Text>
                            <View style={{flexDirection: 'column'}}>
                                <Text style={styles.text}>{about1}</Text>
                                <View style={styles.space}/>
                                <Text style={styles.text}>{about2}</Text>
                            </View>
                        </View>

                        <View style={styles.view}>
                            <Text style={styles.subtitle}>Work experience</Text>
                            {experience.map((ex, i) => {
                                return (
                                    <View key={ex.name} style={{flexDirection: 'column'}}>
                                        <Text style={{...styles.subtitle, marginTop:i ===0? 0 : 10}}>{ex.job} at {ex.name}</Text>
                                        <Text style={styles.date}>{ex.date}</Text>
                                        {ex.description.map((d, index) => {
                                            return (<Text key={'d' + index} style={styles.description}>• {d}</Text>)
                                        })}
                                    </View>
                                )
                            })}
                        </View>
                    </View>


                    <View style={styles.contentRight}>
                        <View style={{flexDirection: 'column'}}>
                            <Text style={styles.subtitle}>Contact</Text>
                            <Text style={styles.text}>+1 (829) 299-5294</Text>
                            <Text style={{...styles.text,marginTop:5, color:'#01579b'}}>jansihdez@gmail.com</Text>
                            <View style={{flexDirection:"row", marginTop:5}}>
                                <Link style={{...styles.text, color:'#01579b'}} src={'https://github.com/jansi-hernandez'}>GitHub</Link>
                                <Link style={{...styles.text, marginLeft:5, color:'#01579b'}} src={'https://www.linkedin.com/in/jansihernandez/'}>LinkedIn</Link>
                            </View>

                            <View style={styles.view}>
                                <Text style={styles.subtitle}>Skills</Text>
                                {skills.map((s, i) => {
                                    return (
                                        <View key={s.name} style={{flexDirection: 'column', marginBottom:5}}>
                                            <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                                                <Text style={{color:'#263238', fontSize:9}}>{s.name}</Text>
                                                <Text style={{color:'#263238', fontSize:9}}>{s.value}%</Text>
                                            </View>
                                            <View style={{width:'100%', marginTop:5, backgroundColor:'#e5e5e5'}}>
                                                <View style={{width:`${s.value}%`, borderRadius:'4', backgroundColor:'#01579b', height:4}}/>
                                            </View>
                                        </View>
                                    )
                                })}
                            </View>

                            <View style={styles.view}>
                                <Text style={styles.subtitle}>Other Skills</Text>
                                {otherSkills.map((s, i) => {
                                    return (
                                        <View key={s.name} style={{flexDirection: 'column', marginBottom:5}}>
                                            <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                                                <Text style={{color:'#263238', fontSize:9}}>{s.name}</Text>
                                                <Text style={{color:'#263238', fontSize:9}}>{s.value}%</Text>
                                            </View>
                                            <View style={{width:'100%', marginTop:5, backgroundColor:'#e5e5e5'}}>
                                                <View style={{width:`${s.value}%`, borderRadius:'4', backgroundColor:'#01579b', height:4}}/>
                                            </View>
                                        </View>
                                    )
                                })}
                            </View>

                            <View style={styles.view}>
                                <Text style={styles.subtitle}>Languages</Text>
                                {languagesSkills.map((s, i) => {
                                    return (
                                        <View key={s.name} style={{flexDirection: 'column', marginBottom:5}}>
                                            <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                                                <Text style={{color:'#263238', fontSize:9}}>{s.name}</Text>
                                                <Text style={{color:'#263238', fontSize:9}}>{s.value}%</Text>
                                            </View>
                                            <View style={{width:'100%', marginTop:5, backgroundColor:'#e5e5e5'}}>
                                                <View style={{width:`${s.value}%`, borderRadius:'4', backgroundColor:'#01579b', height:4}}/>
                                            </View>
                                        </View>
                                    )
                                })}
                            </View>

                        </View>
                    </View>
                </View>
            </View>
        </Page>
    </Document>
}


const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#fff',
        padding: 20,
    },
    header: {
        justifyContent: 'flex-start',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 15
    },
    title: {
        fontFamily: 'Roboto',
        fontSize: 20,
        fontWeight: 'bold'
    },
    subtitle: {
        fontSize: 12,
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        marginBottom: 5
    },
    text: {
        fontSize: 11,
        lineHeight: 1.2,
        fontWeight: 'normal',
        color:'#263238',
        marginBottom:2
    },

    date: {
        color:'#455a64',
        fontSize: 10,
        lineHeight: 1.2,
        fontWeight: 'normal',
        marginBottom:5
    },

    description: {
        color:'#263238',
        fontSize: 11,
        lineHeight: 1.2,
        fontWeight: 'normal',
        marginBottom:8
    },

    body: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },

    view: {
        marginVertical: 10
    },
    contentLeft: {
        width: '65%'
    },
    contentRight: {
        width: '35%',
        paddingLeft: 20
    },
    space: {
        marginVertical: 5
    }

});
