import React from 'react';
import {isMobile} from 'react-device-detect';
import './App.css';
import CssBaseline from '@mui/material/CssBaseline';
import {Stack, Box} from "@mui/material";
import Home from "./componets/Home";
import About from "./componets/About";
import Contact from "./componets/Contact";
import NavigationButton from "./componets/NavigationButton";
import Menu from './componets/Menu'
import Projects from "./componets/Projects";
import {Route, Routes} from "react-router-dom";
import ScrollToTop from "./componets/ScrollToTop";

export default function App() {

    React.useEffect(() => {
        if ("caches" in window) {
            caches.keys().then((names) => {
                names.forEach((name) => {
                    caches.delete(name).catch(() => {});
                });
                if (names.length && !names.includes('images')){
                    setTimeout(()=>{
                        window.location.reload(true);
                    },2000)

                }
            });
        }
    }, [])

    return (
        <div className="App">
            <CssBaseline/>
            <NavigationButton/>
            <ScrollToTop/>

            <Stack direction="row">

                {!isMobile && (
                    <Box sx={{width: '25%'}}>
                        <Menu/>
                    </Box>
                )}


                <Box sx={{width: isMobile ? '100%' : '75%'}}>
                    <Routes>
                        <Route element={<Home/>} path="/"/>
                        <Route element={<About/>} path="/about"/>
                        {/*<Route element={<Projects/>} path="/projects"/>*/}
                        <Route element={<Contact/>} path="/contact"/>
                    </Routes>
                </Box>
            </Stack>

        </div>
    );
}

