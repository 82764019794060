import React from 'react';
import {Button, Container, Stack, Box, Chip} from "@mui/material";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import profile from './../assest/profile2.JPG'
import {zoomInDown} from 'react-animations';
import styled, {keyframes} from 'styled-components';
import {about1, about2} from "../util/constants";
import {useNavigate} from "react-router-dom";
import {PDFDownloadLink} from '@react-pdf/renderer';
import DocumentPdf from '../componets/DocumentPdf'
import {isMobile} from 'react-device-detect';
import HeaderMobile from "./HeaderMobile";

const zoomInAnimation = keyframes`${zoomInDown}`;


const ZoomInDiv = styled.div`
  animation: 2s ${zoomInAnimation};
  max-width: 400px;
  height: 580px;
  position: absolute;
  left: calc(25% - 200px);
  top: 45%;
  transform: translate(-5%, -50%);
`;

const SlideInDownDiv = styled.div`
  animation: 2s ${zoomInAnimation};
  flex-direction: column;
  display: flex;
`;

const FadeInRightAnimationDiv = styled.div`
  animation: 2s ${zoomInAnimation};
  flex-direction: row;
  display: flex;
`;

export default function Home() {

    const navigate = useNavigate();


    return (
        <Container maxWidth="xl">
            {isMobile ? (
                <Stack direction="column" className={'padding'}>
                    <SlideInDownDiv>
                        <HeaderMobile/>
                        <span className={'description'} style={{marginTop: 15}}>{about1}</span>
                        <span className={'description'} style={{marginTop: 15}}>{about2}</span>
                    </SlideInDownDiv>

                    <FadeInRightAnimationDiv>
                        <Stack direction="column" spacing={2} sx={{paddingTop: 5, width:'100%'}}>
                            {/*<PDFDownloadLink style={{textDecoration: 'none'}} document={<DocumentPdf/>}*/}
                            {/*                 fileName="Jansi Hernandez.pdf">*/}
                            {/*    {({blob, url, loading, error}) =>*/}
                            {/*        <Button onClick={()=>window.open(url)} fullWidth color={'info'} size={'large'}*/}
                            {/*                sx={{*/}
                            {/*                    marginRight: 4,*/}
                            {/*                    textTransform: 'capitalize',*/}
                            {/*                    fontSize: 18,*/}
                            {/*                    backgroundColor: '#000',*/}
                            {/*                    borderColor: '#000',*/}
                            {/*                    color: "#fff",*/}
                            {/*                    "&:hover": {*/}
                            {/*                        color: "#000",*/}
                            {/*                        borderColor: '#fff',*/}
                            {/*                        backgroundColor: '#fff',*/}
                            {/*                    },*/}
                            {/*                }}*/}
                            {/*                variant="contained"*/}
                            {/*                endIcon={<DownloadOutlinedIcon/>}>*/}
                            {/*            Download CV*/}
                            {/*        </Button>*/}
                            {/*    }*/}
                            {/*</PDFDownloadLink>*/}

                            <Button fullWidth onClick={() => navigate('/contact')} color={'info'} size={'large'}
                                    sx={{
                                        textTransform: 'capitalize',
                                        fontSize: 18,
                                        color: "#000",
                                        borderColor: '#000',
                                        backgroundColor: '#fff',
                                        "&:hover": {
                                            backgroundColor: '#000',
                                            borderColor: '#000',
                                            color: "#fff",
                                        },
                                    }}
                                    variant="outlined"
                                    endIcon={<SendOutlinedIcon/>}>
                                Contact me
                            </Button>
                        </Stack>
                    </FadeInRightAnimationDiv>

                </Stack>
            ) : (
                <Box className={'home-profile'}>
                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={6}>
                        <ZoomInDiv>
                            <img
                                style={{objectFit: 'cover', borderRadius: 5}}
                                height={'100%'}
                                width={'100%'}
                                src={profile}
                                alt="Jansi Hernandez"
                            />
                        </ZoomInDiv>
                        <Stack className={'resumeProfile'} direction="column" spacing={2}>
                            <Stack direction="column">
                                <SlideInDownDiv>
                                    <span className={'text1'}>Hi, my name is</span>
                                    <span className={'textName'} style={{marginTop: 15}}>Jansi Hernandez</span>
                                    <Chip style={{alignSelf: 'flex-start', backgroundColor: '#000', color: '#fff'}}
                                          label={"Full Stack Developer"}/>
                                    <span className={'description'} style={{marginTop: 15}}>{about1}</span>
                                    <span className={'description'} style={{marginTop: 15}}>{about2}</span>
                                </SlideInDownDiv>

                                <Stack direction="row" spacing={2} sx={{paddingTop: 5}}>
                                    <FadeInRightAnimationDiv>

                                        {/*<PDFDownloadLink style={{textDecoration: 'none'}} document={<DocumentPdf/>}*/}
                                        {/*                 fileName="Jansi Hernandez.pdf">*/}
                                        {/*    {({blob, url, loading, error}) =>*/}
                                        {/*        <Button color={'info'} size={'large'}*/}
                                        {/*                sx={{*/}
                                        {/*                    marginRight: 4,*/}
                                        {/*                    textTransform: 'capitalize',*/}
                                        {/*                    fontSize: 18,*/}
                                        {/*                    backgroundColor: '#000',*/}
                                        {/*                    borderColor: '#000',*/}
                                        {/*                    color: "#fff",*/}
                                        {/*                    "&:hover": {*/}
                                        {/*                        color: "#000",*/}
                                        {/*                        borderColor: '#fff',*/}
                                        {/*                        backgroundColor: '#fff',*/}
                                        {/*                    },*/}
                                        {/*                }}*/}
                                        {/*                variant="contained"*/}
                                        {/*                endIcon={<DownloadOutlinedIcon/>}>*/}
                                        {/*            Download CV*/}
                                        {/*        </Button>*/}
                                        {/*    }*/}
                                        {/*</PDFDownloadLink>*/}

                                        <Button onClick={() => navigate('/contact')} color={'info'} size={'large'}
                                                sx={{
                                                    textTransform: 'capitalize',
                                                    fontSize: 18,
                                                    color: "#000",
                                                    borderColor: '#000',
                                                    backgroundColor: '#fff',
                                                    "&:hover": {
                                                        backgroundColor: '#000',
                                                        borderColor: '#000',
                                                        color: "#fff",
                                                    },
                                                }}
                                                variant="outlined"
                                                endIcon={<SendOutlinedIcon/>}>
                                            Contact me
                                        </Button>
                                    </FadeInRightAnimationDiv>
                                </Stack>

                            </Stack>
                        </Stack>
                    </Stack>
                </Box>
            )}
        </Container>
    );
}


