import React from 'react';
import {isMobile} from 'react-device-detect';
import {Box, Chip, IconButton, Stack} from "@mui/material";
import profile from "../assest/profile2.JPG";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

export default function HeaderMobile() {
    return (
        <>
            {isMobile && (
                <Stack direction="column" justifyContent="center" alignItems="center" spacing={1}>
                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                        <Stack direction="column" alignItems="center">
                            <img
                                style={{objectFit: 'cover', borderRadius: 1000,}}
                                height={135}
                                width={135}
                                src={profile}
                                alt="Jansi Hernandez"
                            />
                        </Stack>
                        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start">
                            <span className={'text1'} style={{fontSize: 14}}>Hi, my name is</span>
                            <span className={'textName'} style={{fontSize: 20}}>Jansi Hernandez</span>
                            {/*<span className={'textName'} style={{fontSize: 16}}>jansihdez@gmail.com</span>*/}
                            {/*<span className={'textName'} style={{fontSize: 16}}>(829) 299-5294</span>*/}
                            <div style={{backgroundColor:'#000', borderRadius:4}}>
                                <span style={{color:'#fff', fontSize:12, padding:3, paddingRight:5, paddingLeft:5}}> Full Stack Developer </span>
                            </div>
                            <Stack direction="row">
                                <IconButton onClick={() => {window.open('https://github.com/jansi-hernandez', '_blank')}} style={{color: '#000'}}>
                                    <GitHubIcon style={{fontSize: 30}}/>
                                </IconButton>

                                <IconButton onClick={() => {window.open('https://www.linkedin.com/in/jansihernandez', '_blank')}} style={{color: '#000'}}>
                                    <LinkedInIcon style={{fontSize: 30}}/>
                                </IconButton>
                            </Stack>
                            {/*<div style={{height:40}}/>*/}
                        </Stack>
                    </Stack>


                </Stack>
            )}
        </>
    );
}


